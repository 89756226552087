import {
  type LocalPersistentStorageConfig,
  type StorageItem,
} from './LocalPersistentStorage.service'

export class MockLocalPersistentStorage {
  /*
   * Default time-to-live (TTL) values in milliseconds
   */
  static readonly DEFAULT_TTL = {
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
    MONTH: 30 * 24 * 60 * 60 * 1000,
  }

  private readonly defaultTTL: number | null
  private readonly onError: (error: unknown) => void

  private static isStorageItem<T = any>(item: any): item is StorageItem<T> {
    return typeof item === 'object' && 'value' in item && 'expiry' in item
  }

  private data: Record<string, StorageItem<any>> = {}

  /**
   * Creates an instance of LocalPersistentStorage service.
   *
   * @param {LocalPersistentStorageConfig} [config={}] - Configuration object for the local persistent storage.
   *  @param {function} [config.onError] - Function to handle errors. It should accept an Error object.
   * @param {number | null} [config.defaultTTL=null] - Default time-to-live (TTL) for stored items. If not provided, defaults to null.
   */
  constructor(
    config: LocalPersistentStorageConfig = {
      onError: (error: unknown) => console.error('Storage error:', error),
    }
  ) {
    const { defaultTTL = null, onError } = config
    this.defaultTTL = defaultTTL
    this.onError = onError
  }

  get = jest.fn().mockImplementation((key: string) => {
    try {
      const item = this.data[key]

      if (!item) {
        return null
      }

      // check if previously stored item is not a StorageItem
      // this is to handle the case where the storage item was set before the introduction of StorageItem
      if (!MockLocalPersistentStorage.isStorageItem(item)) {
        return item
      }

      // check if item has expired
      if (item.expiry !== null && Date.now() > item.expiry) {
        // remove expired item
        this.remove(key)
        return null
      }

      return item.value
    } catch (error) {
      this.onError(error)
    }
  })

  set = jest
    .fn()
    .mockImplementation((key: string, value: any, ttl?: number | null) => {
      const item: StorageItem<any> = { value, expiry: null }

      // determine ttl to use
      const _ttl = ttl !== undefined ? ttl : this.defaultTTL

      // Set an expiration if TTL has been set
      if (_ttl !== null && _ttl > 0) {
        item.expiry = Date.now() + _ttl
      }

      this.data[key] = item
    })

  remove = jest.fn().mockImplementation((key: string) => {
    delete this.data[key]
  })

  clear = jest.fn().mockImplementation(() => {
    this.data = {}
  })
}
