import * as React from 'react'

import { useSelector } from 'react-redux'
import { Redirect, Route, type RouteProps } from 'react-router'

import {
  type FeatureFlagKey,
  type FeatureFlags,
} from '@webapp/core/services/FeatureFlag'
import {
  makeSelectFeatureFlags,
  makeSelectFeatureSwitches,
  makeSelectNavigationPermissions,
  makeSelectPermissions,
  makeSelectRole,
} from '@webapp/core/stores/global'
import {
  type FeatureSwitches,
  type NavigationPermissions,
  PermissionGroupNames,
} from '@webapp/models'
import { type PermissionEnum } from '@webapp/platform/iam/entities'

export interface IProtectedRouteProps extends RouteProps {
  navigationPermissions?: NavigationPermissions[]
  permissions?: PermissionEnum[]
  featureFlags?: FeatureFlagKey[]
  featureSwitches?: (keyof FeatureSwitches)[]
  isSuperuserRoleRequired?: boolean
  /**
   * Custom filter function to allow checking complex rules for route access.
   * Note that it will take precedence over all other props,
   * for example if `customFilter` returns false, but the other props are satisfied,
   * the route won't be accessible.
   * @param featureSwitches: FeatureSwitches - Feature switches from the store with their values
   * @param featureFlags: FeatureFlags - Feature flags from the store with their values
   * @param permissions: PermissionEnum[] - Current user permissions
   * @param navigationPermissions: NavigationPermissions[] - Current user navigation permissions
   */
  customFilter?: ({
    featureSwitches,
    featureFlags,
    permissions,
    navigationPermissions,
  }: {
    featureSwitches: FeatureSwitches
    featureFlags: FeatureFlags
    permissions: PermissionEnum[]
    navigationPermissions: NavigationPermissions[]
  }) => boolean
}

export const ProtectedRoute = React.memo(
  ({
    navigationPermissions: requiredNavigationPermissions = [],
    permissions: requiredPermissions = [],
    featureFlags: requiredFeatureFlags = [],
    featureSwitches: requiredFeatureSwitches = [],
    component: Component,
    render,
    isSuperuserRoleRequired,
    customFilter,
    ...otherProps
  }: IProtectedRouteProps) => {
    const userNavigationPermissions = useSelector(
      makeSelectNavigationPermissions()
    )
    const userPermissions =
      useSelector(makeSelectPermissions())?.map(
        (permission) => permission.codename
      ) || []
    const userRole = useSelector(makeSelectRole())
    const featureFlags = useSelector(makeSelectFeatureFlags())
    const featureSwitches = useSelector(makeSelectFeatureSwitches())

    const isSuperUser =
      userRole &&
      (userRole.name === PermissionGroupNames.SUPERUSER ||
        userRole.name === PermissionGroupNames.SITE_ADMINISTRATOR)

    const hasSuperuserAccess =
      !isSuperuserRoleRequired || (isSuperuserRoleRequired && isSuperUser)

    let hasAccess = false

    if (customFilter) {
      hasAccess = customFilter({
        featureSwitches,
        featureFlags,
        permissions: userPermissions,
        navigationPermissions: userNavigationPermissions,
      })
    } else {
      const hasNavigationPermissions = requiredNavigationPermissions.every(
        (permission) => userNavigationPermissions.indexOf(permission) >= 0
      )
      const hasPermissions = requiredPermissions.every((permission) => {
        return userPermissions.includes(permission)
      })
      const hasFeatureFlags = requiredFeatureFlags.every(
        (ff) => featureFlags[ff]
      )
      const hasFeatureSwitches = requiredFeatureSwitches.every(
        (ff) => featureSwitches[ff]
      )

      hasAccess =
        hasNavigationPermissions &&
        hasPermissions &&
        hasFeatureFlags &&
        hasFeatureSwitches
    }

    const isAllowed = hasAccess && hasSuperuserAccess
    return (
      <Route
        {...otherProps}
        render={(props) => {
          if (isAllowed && Component) {
            return <Component {...props} />
          } else if (isAllowed && render) {
            return render(props)
          } else {
            return <Redirect to='/' />
          }
        }}
      />
    )
  }
)

ProtectedRoute.displayName = 'ProtectedRoute'
