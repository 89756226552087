import { type IPersistentStorage } from '@webapp/infrastructure/interfaces/services/IPersistentStorage'

export interface StorageItem<T> {
  value: T
  expiry: number | null // expiration timestamp - date.now() + ttl
}

export interface LocalPersistentStorageConfig {
  onError: (error: unknown) => void
  defaultTTL?: number | null
}

export class LocalPersistentStorage implements IPersistentStorage {
  /*
   * Default time-to-live (TTL) values in milliseconds
   */
  static readonly DEFAULT_TTL = {
    HOUR: 60 * 60 * 1000,
    DAY: 24 * 60 * 60 * 1000,
    WEEK: 7 * 24 * 60 * 60 * 1000,
    MONTH: 30 * 24 * 60 * 60 * 1000,
  }
  private readonly defaultTTL: number | null
  private readonly onError: (error: unknown) => void

  private static isStorageItem<T = any>(item: any): item is StorageItem<T> {
    return typeof item === 'object' && 'value' in item && 'expiry' in item
  }

  /**
   * Creates an instance of LocalPersistentStorage service.
   *
   * @param {LocalPersistentStorageConfig} [config={}] - Configuration object for the local persistent storage.
   * @param {function} [config.onError] - Function to handle errors. It should accept an Error object.
   * @param {number | null} [config.defaultTTL=null] - Default time-to-live (TTL) for stored items. If not provided, defaults to null.
   */
  constructor(
    config: LocalPersistentStorageConfig = {
      onError: (error: unknown) => console.error('Storage error:', error),
    }
  ) {
    const { defaultTTL = null, onError } = config
    this.defaultTTL = defaultTTL
    this.onError = onError
  }

  get<T = any>(key: string): Nullable<T> {
    try {
      const value = localStorage.getItem(key)

      if (!value) {
        return null
      }

      try {
        const item: StorageItem<T> = JSON.parse(value)

        // check if previously stored item is not a StorageItem
        // this is to handle the case where the storage item was set before the introduction of StorageItem
        if (!LocalPersistentStorage.isStorageItem(item)) {
          return item
        }

        // check if item has expired
        if (item.expiry !== null && Date.now() > item.expiry) {
          // remove expired item
          this.remove(key)
          return null
        }

        return item.value
      } catch (error) {
        this.onError(error)
        return null
      }
    } catch (error) {
      this.onError(error)
      return null
    }
  }

  /**
   * @param key The storage key
   * @param value The value to store
   * @param ttl Optional time-to-live in milliseconds.
   *            If undefined, the defaultTtl will be used.
   *            If explicitly set to null, the item won't expire.
   */
  set<T = any>(key: string, value: T, ttl?: number | null): void {
    const item: StorageItem<T> = {
      value,
      expiry: null,
    }

    // determine ttl to use
    const _ttl = ttl !== undefined ? ttl : this.defaultTTL

    // Set an expiration if TTL has been set
    if (_ttl !== null && _ttl > 0) {
      item.expiry = Date.now() + _ttl
    }

    try {
      localStorage.setItem(key, JSON.stringify(item))
    } catch (error) {
      this.onError(error)
      throw error
    }
  }

  remove(key: string): void {
    localStorage.removeItem(key)
  }

  clear(): void {
    localStorage.clear()
  }
}
