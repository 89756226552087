import * as Sentry from '@sentry/browser'

import {
  LocalPersistentStorage,
  type LocalPersistentStorageConfig,
} from './LocalPersistentStorage.service'

export { LocalPersistentStorage } from './LocalPersistentStorage.service'
export { MockLocalPersistentStorage } from './LocalPersistentStorage.service.mock'

/*
 * Creates a LocalPersistentStorage instance with Sentry error handling pre-configured
 *
 * @param config Configuration options, defaultTTL can be customized
 * @returns Configured LocalPersistentStorage instance
 */
export function createLocalStorage(
  config: Partial<LocalPersistentStorageConfig> = {}
) {
  return new LocalPersistentStorage({
    onError: config.onError || Sentry.captureException,
    ...config,
  })
}
